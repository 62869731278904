export const Data = [
  {
    id: '8',
    title: '회사의 대표적인 영업제도가 궁금합니다.',
    answer: '아이티엑스마케팅은 크게 2가지 유형의 채널을 운영하고 있습니다.',
    a1: '1. 회사가 정한 최소 기준을 따라 자유로운 운영을 하는 지사형 채널과',
    a2: '2. 회사의 영업제규정을 준수하며 마케팅지원을 받아 운영하는 거점형 채널로 구성되어 있습니다.',
    coment: '아이티엑스마케팅에서 여러분의 꿈을 실현해 보시기 바랍니다.'
  },
  {
    id: '7',
    title: '회사의 영업지원 시스템은 어떤 특징이 있나요?',
    answer: '아이티엑스마케팅에는 다양한 분야의 전문가로 구성되어 있습니다.',
    a1: '① 상속과 증여를 전문적으로 연구하는 상속증여 연구소',
    a2: '② 고객의 라이프사이클의 이해부터 자산 포트폴리오 자문 및 어프로치와 클로징까지 sales 전과정을 전문적으로 교육하는 자산관리본부',
    a3: '③ 기업의 입장에서 생각하고 연구하는 법인지원센터 및 기업전략본부',
    a4: '④ 이슈가 되는 상품을 발굴하고 상품화하는 상품개발연구소',
    a5: '⑤ FP성향별 맞춤형 가망고객시장을 제공하는 채널지원팀',
    a6: '⑥ 장기보험은 물론 일반보험 영역까지 보험의 모든 분야에 걸쳐 세일즈 코디네이터를 통한 영업지원을 제공해 드립니다.',
    coment: '위와 같이 각 분야의 전문가그룹을 통하여 영업 노하우를 공유하고 교육 및 동행까지도 가능한 영업지원 시스템을 갖추고 있습니다.'
  },
  {
    id: '6',
    title: '회사는 안정적인가요?',
    answer: '당사는 매년 성장하는 구조임을 말씀드립니다. \n특히, FP분들의 안정적인 영업환경을 만들어 드리기 위한 선지급 제도는 물론 FP 맞춤형 마케팅 지원까지 \n다양한 영업환경을 만들어 드리고 있으며, 이를 실행하기 위한 안정적인 재무구조도 갖추고 있습니다.',
    coment: '더불어 재무구조를 비롯한 기업평가를 통해 기업가치를 인정받고 있으며, 안정적이고 원활한 금융권 자금의 유입, \n22년 대규모 투자 유치가 확정되어 FP가족들의 영업활동 지원을 위한 \n다양한 인프라 고도화, 마케팅 강화, 활동시장 확대 등의 진행하고 있습니다.'
  },
  {
    id: '5',
    title: '보험플러스는 어떤 곳인가요?',
    answer: '보험플러스는 생활밀착형 공간을 통해 보험서비스를 제공하는 아이티엑스마케팅만의 차별화된 보험브랜드 입니다. \n생활밀착형 공간이란 병원, 마트, 로드샵 등으로 방문하시는 고객과 연관된 보험서비스를 제공하여 고객에게 \n보험 니즈를 환기시키고 무료보험컨설팅을 제공합니다. 예컨대 병원의 경우 진료 후에 바로 보험금을 청구할 수 있도록 \n보험금청구 전용 키오스크가 비치되어 있어 보험금 청구를 지원해드리면서 보험상담을 제공하게 됩니다.',
  },
  {
    id: '4',
    title: '보험플러스에서 활동하면 어떤 점이 좋은 가요?',
    answer: '보험플러스는 보험을 필요로 하는 공간을 직접 방문하시기 때문에 보험에 대한 니즈가 강하여 \n보험금청구는 물론 보험비교, 보장분석 등의 보험서비스를 제공하는데 부담이 없다는 점이 \n가장 큰 장점이라고 할 수 있습니다.',
    coment: '현재 20여개 보험플러스가 운영 중에 있으며 2022년 내 100여개로 확대할 예정입니다. \n또한 지인시장 위주로 영업을 하는 FP분들이 가망고객을 확보하기 위한 채널로 활용하는데 \n유리하며 무엇보다 회사의 영업지원 시스템이 갖추어져 있어 입사 즉시 활동이 가능합니다.'
  },
  {
    id: '3',
    title: '보험플러스에서 활동하는데 필요한 별도의 자격이 있나요?',
    answer: '보험플러스는 회사에 입사하시는 FP 모든 분들에게 열린 시장으로 생손보 자격 외에 특별한 자격요건이 \n필요하진 않습니다. 다만 활동하시기 전에 회사가 정한 교육과정을 이수하셔야 합니다. \n커리큐럼은 병원, 마트 등 보험플러스의 채널에 따른 고객응대 방식, 영업노하우, 마케팅 방식 등으로 \n구성이 되며 약 2~3회에 걸쳐 교육이 이루어집니다.',
  },
  {
    id: '2',
    title: '회사가 FP분들에게 제공하는 고객 pool은 어떤 것들이 있나요?',
    answer: '타GA와 비교되는 아이티엑스마케팅의 가장 큰 경쟁력이 바로 회사가 보유한 고객 pool입니다. \n병원을 기반으로 한 보험금 청구고객, 자체 플랫폼을 통해 수집 되는 소상공인 및 다양한 경로의 \n특화고객 pool을 보유하고 있으며 아이티엑스마케팅만의 노하우가 접목된 형태로 고객을 확보\n하고 있습니다. 타GA에서도 벤치마킹을 하기 위해 문의 또는 방문을 할 정도의 경쟁력을 갖추고\n있습니다.',
  },
  {
    id: '1',
    title: '광고 유형별로 심의 절차와 시간은 얼마나 소요되나요?',
    answer: '블로그, 인스타그램 등의 SNS 게시글, 옥.내외 광고물 등은 회사 자체 심의 사항으로 해당 시안을 \n회사 준법감시인에게 제출 하고, 심의 결과 통보는 광고시안 제출일로부터 2영업일 이내 완료됩니다. \n특히, 최근 활성화되고 있는 동영상 광고의 경우는',
    a1: '① 광고시안 제출 마감 : 준법감시인 접수 (D-day)',
    a2: '② 준법감시인 심의 및 협회제출용 서류 작성 (D + 1영업일)',
    a3: '③ 준법감시인이 협회 사전광고 심의 신청 (D + 2영업일)',
    a4: '④ 협회 심의 결과 통보 (D + 9영업일)',
  },
];


export const getPostByNo = id => {
  const array = Data.filter(x => x.id === id);
  if (array.length === 1) {
    return array[0];
  }
  return null;
}