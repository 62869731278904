// import list1 from '../img/sub/investList1.png';
// import list2 from '../img/sub/investList2.png';
// import list3 from '../img/sub/investList3.png';

// export const Data = [
//   {
//     id: '12',
//     idx: '12',
//     type: '보험이야기',
//     img: list1,
//     title: '대장 내시경 중 용종제거,\n보험금 청구 될까?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 건강검진  #플러미 보험이야기',
//     writer: '강세훈',
//     date: '2022. 01. 28',
//     hit: '1,231',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: 'none'
//   },
//   {
//     id: '11',
//     idx: '11',
//     type: '보험플러스',
//     img: list2,
//     title: '실손보험금 청구 필수품은?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 4세대 실손보험  # 병원라운지',
//     writer: '정의석',
//     date: '2022. 02. 01',
//     hit: '345',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '10',
//     idx: '10',
//     type: '보험플러스',
//     img: list3,
//     title: '아랫집 누수사고, 보험\n처리할 수 있나요?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 화재보험  # 보험칼럼',
//     writer: '강세훈',
//     date: '2022. 02. 01',
//     hit: '300',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '9',
//     idx: '9',
//     type: '보험이야기',
//     img: list1,
//     title: '대장 내시경 중 용종제거,\n보험금 청구 될까?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 건강검진  #플러미 보험이야기',
//     writer: '강세훈',
//     date: '2022. 01. 28',
//     hit: '1,231',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '8',
//     idx: '8',
//     type: '보험플러스',
//     img: list2,
//     title: '실손보험금 청구 필수품은?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.', 
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 4세대 실손보험  # 병원라운지',
//     writer: '정의석',
//     date: '2022. 02. 01',
//     hit: '345',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '7',
//     idx: '7',
//     type: '보험플러스',
//     img: list3,
//     title: '아랫집 누수사고, 보험\n처리할 수 있나요?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.', 
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 화재보험  # 보험칼럼',
//     writer: '강세훈',
//     date: '2022. 02. 01',
//     hit: '300',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '6',
//     idx: '6',
//     type: '보험이야기',
//     img: list1,
//     title: '대장 내시경 중 용종제거,\n보험금 청구 될까?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 건강검진  #플러미 보험이야기',
//     writer: '강세훈',
//     date: '2022. 01. 28',
//     hit: '1,231',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '5',
//     idx: '5',
//     type: '보험플러스',
//     img: list2,
//     title: '실손보험금 청구 필수품은?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 4세대 실손보험  # 병원라운지',
//     writer: '정의석',
//     date: '2022. 02. 01',
//     hit: '345',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '4',
//     idx: '4',
//     type: '보험플러스',
//     img: list3,
//     title: '아랫집 누수사고, 보험\n처리할 수 있나요?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 화재보험  # 보험칼럼',
//     writer: '강세훈',
//     date: '2022. 02. 01',
//     hit: '300',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '3',
//     idx: '3',
//     type: '보험이야기',
//     img: list1,
//     title: '대장 내시경 중 용종제거,\n보험금 청구 될까?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 건강검진  #플러미 보험이야기',
//     writer: '강세훈',
//     date: '2022. 01. 28',
//     hit: '1,231',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '2',
//     idx: '2',
//     type: '보험플러스',
//     img: list2,
//     title: '실손보험금 청구 필수품은?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 4세대 실손보험  # 병원라운지',
//     writer: '정의석',
//     date: '2022. 02. 01',
//     hit: '345',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: '',
//     next: ''
//   },
//   {
//     id: '1',
//     idx: '1',
//     type: '보험플러스',
//     img: list3,
//     title: '아랫집 누수사고, 보험\n처리할 수 있나요?',
//     contents: '프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리.',
//     contents2: '프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 내시경 용종제거 내용 들어갈 자리 프로미의 대장 내시경 용종제거 내용 들어갈 자리프로미의 대장 프로미의 대장',
//     hash: '# 화재보험  # 보험칼럼',
//     writer: '강세훈',
//     date: '2022. 02. 01',
//     hit: '300',
//     keyword1: '보험플러스',
//     keyword2: '대장내시경',
//     keyword3: '건강검진',
//     prev: 'none',
//     next: ''
//   },
// ];

export const Data = [
  {
    id: '1',
    idx: '1',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: '',
    list_style1: '',
    list_style2: 'list_style2',
    next: '왜 필요할까? series 어린이보험',
    prev: '',
    class: '',
    class2: 'none',
    title: '왜 필요할까? series 암보험',
    subtit1: '암보험! 왜 필요할까?',
    cont1_1: '대한민국 사망원인 1위! 암!!\n2018년 신규 암 등록환자 30만 2천여명!\n암치료에 소요된 비용 평균 2,877만원!\n기대수명 82세까지 생존할 경우 암에 결릴 확률은 36.2% 10명 중 3명 이상이 암 발병',
    cont1_2: '암에 걸려 치료를 받는 경우\n치료비의 95%는 건강보험공단이 지원(급여부분) 하고\n5%는 본인 부담인데 의료실비보험에서 보장 가능합니다.\n그럼 암보험에 가입을 하지 않아도 치료비 부담이 없는데 암보험은 왜 필요할까요?\n암보험은 암을 치료하는 기간동안\n생활비, 교육비, 재할비를 확보하기 위해 반드시 필요합니다',
    subtit2: '암보험 보장내용은?',
    cont2_1: '1. 암 진단비 : 암으로 진단받았을 때 정액으로 지급되며 수술이나 치료 여부에 상관없이 진단서만 제출하면 바로 지급됩니다.',
    cont2_2: '2. 암 입원비 : 암 진단 확정 후 암 치료를 목적으로 일정 기간 이상 입원하면 지급되는데 1일한도, 지급 기간 한도가 정해져 있습니다.',
    cont2_3: '3. 암 수술비 : 암으로 확정된 후 암 치료를 목적으로 수술을 받으면 지급되는데 내시경이나 복강경 수술 등 최신수술법은 수술로 인정되지 않을 수도 있습니다.',
    cont2_4: '4. 암 치료비 : 암 치료를 목적으로 방사선이나 항암치료를 받을 때 지급됩니다.',
    subtit3: '암보험 가입요령 체크하기',
    cont3_list1: '1. 가입시기',
    cont3_list2: '2. 보장기간',
    cont3_list3: '3. 면책기간',
    cont3_list4: '4. 비갱신형',
    cont3_list5: '5. 보험료 납입면제',
    cont3_list6: '6. 전이암과 재발암 보장',
    cont3_list7: '7. 진단금 설정과 보장범위 체크',
    cont3_list8: '8. 보험비교 활용',
    cont3_1: '암은 나이가 많을수록 발병율이 높아지고 그만큼 보험료도 높아집니다. 또, 병력이 있을 경우 가입이 제한되는 경우가 많기 때문에 젊고 건강할 때 가입하는 것이 유리합니다.\n(예 : 자궁근종시 자궁암은 보장에서 제외)',
    cont3_2: '암에 걸리면 치료기간이 길고 40세 이후 꾸준하게 발병율이 높아지기 때문에 보장기간은 90세 이상 최대한 길게 잡는 것이 중요합니다.',
    cont3_3: '암보험 가입일로 부터 90일 이후 바로 100% 보장되는 상품인지, 1~2년 이후에나 보장되는 상품인지 따져 보셔야 합니다.',
    cont3_4: '일정 주기마다 보험료가 인상되는 갱신형보다는 최초 보험료가 유지되는 비갱신형이 유리합니다.',
    cont3_5: '암으로 인해 진단비 및 수술비 수령 후 보험료의 납입면제가 가능한 상품인지 확인하시는 게 좋습니다.',
    cont3_6: '암 종류에 따라 전이되거나 재발하는 경우가 많기 때문에 재진단, 이차암, 전이암 보장이 가능한지 확인하고 추가비용이 발생하더라도 필요에 따라 가입여부를 결정하는 것이 좋습니다.',
    cont3_7: '고액암은 최대 보장금액 많을수록 유리\n소액암 보장범위는 좁고 일반암 보장범위는 넓을수록 유리\n유사암은 최대 보장금액이 클수록 유리',
    cont3_8: '보험비교 서비스를 이용하면 쉽고 간편하게 여러 회사의 상품을 비교해서 저렴하고 효과적인 견적을 볼 수 있습니다.',
  },
  {
    id: '2',
    idx: '2',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: 'bold',
    list_style1: '',
    list_style2: '',
    next: '왜 필요할까? series 연금보험',
    prev: '왜 필요할까? series 암보험',
    class: '',
    class2: '',
    title: '왜 필요할까? series 어린이보험',
    subtit1: '어린이보험! 왜 필요할까?',
    cont1_1: '전체 안전사고 중 어린이 안전사고 36.2% (2017년 한국소비자원)\n 5년간 소아암 환자 13% 증가 (2014년 건강보험심사평가원)',
    cont1_2: '어린이보험은 자녀의 질병과 상해에 대해 종합보장을 해주는 상품으로 0세 즉 태아부터 가입이 가능하고 기본 20~30세, 최장 100세까지 보장을 받을 수 있다.',
    cont1_3: '태아부터 가입을 할 수 있기 때문에 “태아보험”\n100세까지 보장을 받을 수 있기 때문에 “어른이보험”이라고도 불리고 있습니다.',
    subtit2: '어린이보험의 보장내용',
    cont2_list1: '1. 태아보장 : ',
    cont2_list2: '2. 실손의료비 : ',
    cont2_list3: '3. 중대질병 : ',
    cont2_list4: '4. 기타 주요보장 : ',
    cont2_1: '출생위험보장, 선천성이상수술비, 저체중아육아비용, 신생아입원 등 보장',
    cont2_2: '병원비 덜어주는 상해/질병에 의한 입원/통원의료비 보장',
    cont2_3: '암, 뇌혈관질환(+뇌졸중, 뇌출혈), 허혈성 심장질환(+급성심근경색)과 같은 중대질병의 진단비 보장',
    cont2_4: '상해사망/후유장해, 질병사망/후유장해, 골절 및 화상, 각종 질병 수술비, 입원일당, 배상책임, 의료사고법률비용, 학교폭력 등 보장',
    subtit3: '어린이보험 가입요령 체크하기',
    cont3_list1: '1. 태아보험은 임신 사실확인 후 바로 가입',
    cont3_list2: '2. 다자녀 보험료할인 이용',
    cont3_list3: '3. 가입되어 있는 특약과 보장내용 확인',
    cont3_list4: '4. 치아보장 유무와 범위 확인',
    cont3_list5: '5. 암 보장! 특히 신경 써서 가입',
    cont3_1: '태아뿐만 아니라 산모의 임신질환까지 보장을 받을 수 이기 때문에 임신 사실 확인되면 바로 가입을 해야 하며 늦어도 22주 전에 가입을 해야 보험가입이 가능합니다.',
    cont3_2: '가입하려고 하는 자녀(혹은 태아)의 이미 보험에 가입된 형제자매가 있다면 가족관계증명서를 준비하신 후 보험회사에 신청하시면 다자녀가정 보험료 할인을 받을 수 있습니다.',
    cont3_3: '어린이보험에 가입되어 있긴 하지만, 보장범위가 워낙 넓기 때문에 구체적으로 어떤 특약과 보장내용인지 모르는 경우가 많습니다. 약관 혹은 증권을 꼼꼼하게 읽어보시기 바랍니다.',
    cont3_4: '자녀들이 성장하면서 치과에 갈 일이 많이 생기게 됩니다. 별도의 치과보험에 가입할 수도 있지만 어린이 보험에 가입하면서 치아보장특약을 추가하면 좋습니다.',
    cont3_5: '최근 5세 미만의 소아암 발병이 증가하는 추세이며 소아암 자체가 성인에 비해 진행 속도가 빠르고 예후가 좋지 못합니다. 그렇기 때문에 암 보장을 통해 대비를 하는 것이 중요합니다.',
  },
  {
    id: '3',
    idx: '3',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: '',
    list_style1: '',
    list_style2: '',
    next: '왜 필요할까? series 운전자보험',
    prev: '왜 필요할까? series 어린이보험',
    class: '',
    class2: '',
    title: '왜 필요할까? series 연금보험',
    subtit1: '연금보험! 왜 필요할까?',
    cont1_1: '월평균 노후생활비 250만원\n은퇴부터 사망시까지 필요자금 4억원 (2018년국민연금공단)',
    cont1_2: '우리나라 성인 35% 노후준비 없음\n50% 이상이 국민연금에만 의존 (2019년 통계청)',
    cont1_3: '연금보험이란 경제활동을 하는 동안 일정액을 납입하고 은퇴시기에 매월 월급식으로 생활비를 보장받기 위해 준비하는 보험입니다. 예측 가능한 수입이 확보되기 때문에 직장에 다닐 때처럼 안정적인 생활이 가능합니다.',
    subtit2: '연금보험의 종류',
    cont2_list2: '1. 국민연금',
    cont2_list3: '2. 퇴직연금',
    cont2_list4: '3. 개인연금보험 (HOT)',
    cont2_1: '연금의 선진국형 3단계 구조에 맞게 준비할 필요가 있는데 연금의 3단계는 국민연금과 퇴직연금, 개인연금보험이 해당됩니다.',
    cont2_2: '- 기초생활보장 역할을 하는 연금으로 국가가 시행하는 사회보장제도\n- 강제징수로 불만이 있기는 하지만 최고의 노후보장 수단으로 평가\n- 강점 1 : 매년 물가 상승률을 고려해 연금액을 인상\n- 강점 2 : ‘사망 후에도’ 유족이 연금수령 가능',
    cont2_3: '- 회사사정에 따라 지급이 불투명한 퇴직금 제도의 위험을 개선하여 안정적으로 수령이 가능\n- 다양한 운영방법 : 확정급여형, 확정기여형 상품 중 근로자가 상품을 선택\n- 세제혜택 : 추가납입시 연간 700만원 한도로 세제공제 혜택(최대 16.5%)부여',
    cont2_4: '- 여유 있는 노후를 위해 개인이 보험회사를 통해 가입하는 연금\n- 세제혜택 : 세금없이 수령이 가능한 연금 (월납입액 150만원 이하, 5년이상 납입, 10년이상 유지)\n- 복리운영 : 원금 + 이자에 이자를 부여하는 방식으로 수익율이 높음\n- 최저보증이율을 제공하고 있어 다른 금융상품에 비해 높은 경쟁력',
    subtit3: '개인연금보험 가입요령 체크하기',
    cont3_list1: '1. 연금 수령방법에 따라 유리한 상품 선택하기',
    cont3_list2: '2. 연금을 받는 시기',
    cont3_list3: '3. 보험료 납입기간',
    cont3_list4: '4. 거치기간',
    cont3_list5: '5. 보험료 추가납입',
    cont3_list6: '6. 연금보험비교 반드시 활용',
    cont3_1: '①종신형(사망하기 전까지 원금과 이자를 함께 수령) ②상속형(이자만 받다가 사망했을 때 원금수령) ③확정형(정해진 기간까지 원금과 이자를 함께 수령) ④기타(일시납 즉시연금, 순수연금 등) 중 유리한 유형을 선택해서 가입해야 한다',
    cont3_2: '“연금개시일”이라고 하며 연금은 소득이 발생하지 않는 은퇴시점부터 받는 것이 가장 유리하다. 통상 60~65세를 기준으로 한다',
    cont3_3: '연금보험료는 경제상황에 따라 부담이 될 수 있는 보험료이기 때문에 납입기간을 어느 정도로 설정하는지가 중요하다. 10년납이 가장 일반적이다.',
    cont3_4: '이자가 붙으면서 돈이 불어나는 기간으로 길면 길수록 좋다. 거치기간을 늘리는 방법은 보험료 납입기간은 줄이면 된다. 1억을 10년간 납입하기로 했는데 5년만에 납입하게 되면 나머지 5년간 거치기간이 길어진다.',
    cont3_5: '소득수준이 높아지면 보험료를 높여 원금을 올리는 것이 유리하다. 추가납입한 금액은 보험설계사 수당을 떼지 않기 때문에 원금손실이 거의 없다.',
    cont3_6: '개인이 여러 회사의 연금보험상품과 공시이율, 최저보증이율, 사업비, 비과세적용여부 등을 비교하기 쉽지 않기 때문에 반드시 전문가의 상담을 통해 가입을 해야 한다.',
  },
  {
    id: '4',
    idx: '4',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: '',
    list_style1: '',
    list_style2: '',
    next: '왜 필요할까? series 유병자보험',
    prev: '왜 필요할까? series 연금보험',
    class: '',
    class2: '',
    title: '왜 필요할까? series 운전자보험',
    subtit1: '운전자보험! 왜 필요할까?',
    cont1_1: '자동차보험은 의무적으로 가입해야 하는 책임보험으로 자동차 사고를 냈을 때 상대방이 입은 상해나 손해를 주로 보장합니다. 그에 반해 운전자보험은, 운전자 본인의 상해나 손해를 보장하는 보험입니다. 자동차보험이 타인을 위한 보험이라면, 운전자보험은 ‘나를 위한 보험’입니다.',
    cont1_2: '1. 형사책임 보장 : 자동차보험에서 수리비, 치료비 등 민사책임을 보장하는 반면 운전자보험은 합의금이나 벌금과 같은 형사책임을 보장',
    cont1_3: '2. 사고시 생활비 보장 : 교통사고로 인한 후유장해시 생활비를 보장하며 운전자보험에서만 가능',
    cont1_4: '3. 누구나 보장가능 : 자동차 소유여부, 운전면허 여부에 관계없이 운전자, 동승자 뿐만 아니라 보행중인 경우도 보장가능',
    subtit2: '운전자보험의 보장내용',
    cont2_1: '1. 교통상해사망 : 운전 중 사망사고시 보험금 지급',
    cont2_2: '2. 소득보상금 : 사고로 인한 후유장해시 또는 사망시 유가족들에게 보험금 지급',
    cont2_3: '3. 교통사고처리지원금 : 형사합의지원금과 중상해교통사고 처리지원금 보장',
    cont2_4: '4. 방어비용 : 운전 중 사고로 인해 구속 또는 공소제기시 변호사비용, 법률소송비용 등을 보장',
    cont2_5: '5. 벌금 : 운전 중 사고로 인해 벌금형이 확정 된 경우 벌금액 보장',
    cont2_6: '6. 기타 : 긴급비용, 자동차사고 부상보상금, 보복운전피해보상금, 자동차사고 성형치료비용 등',
    subtit3: '운전자보험 가입시 체크해야 할 사항',
    cont3_list1: '1. 교통사고처리지원금 보장은 반드시 가입',
    cont3_list2: '2. 벌금이나 변호사비용은 보장금액이 큰 상품으로 가입',
    cont3_list3: '3. 보험기간은 길수록 유리하고 운전자 성격에 맞는 상품가입',
    cont3_list4: '4. 상해보장을 추가로 가입하는 것을 고려',
    cont3_1: '과거 형사합의금 보장이 없어지면서 표준화된 보장으로 신설된 보장으로 운전자보험의 핵심 보장입니다. 단, 음주/무면허/뺑소니사고는 보장에서 제외되며 중복가입시 비례보상 됩니다.',
    cont3_2: '피해자와 합의를 하더라도 형사책임은 부담을 하기 때문에 벌금이나 소송에 대비해서 보장금액이 큰 상품을 선택하는 것이 유리합니다.',
    cont3_3: '보장기간은 20년, 80세 100세 등 다양하기 때문에 나이를 먹어도 보장을 받을 수 있도록 보장기간은 길게 가입하는 것이 유리하며 운전이 직업인 경우 화물차/영업용/이륜차 운전자보험도 가입이 가능합니다.',
    cont3_4: '골절진단, 상해입원일당 등 교통사고 이외에 일상생활중 발생하는 상해사고도 보장이 가능하도록 가입하는 것이 유리합니다.',
  },
  {
    id: '5',
    idx: '5',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: '',
    list_style1: 'list_style1',
    list_style2: '',
    next: '왜 필요할까? series 종신보험',
    prev: '왜 필요할까? series 운전자보험',
    class: '',
    class2: '',
    title: '왜 필요할까? series 유병자보험',
    subtit1: '유병자보험! 왜 필요할까?',
    cont1_1: '65세 이상 만성질환 보유자 89%\n(만성질환 고혈압, 당뇨, 관절염)',
    cont1_2: '고혈압이나 당뇨, 관절염을 앓고 있는 사람은 위험률이 높다는 이유에서\n보험 가입이 거절되었지만\n최근 유병장수 시대 60세 이상 소비자의 보험보장에 대한 욕구가 늘어나면서\n유병자도 보험보장을 받을 수 있는 “유병자보험”이 탄생',
    cont1_3: '- 유병자 전용 의료실비보험, 암보험, 건강보험은 물론 종신보험까지 가입가능',
    cont1_4: '- 최근 가장 가입자가 급격하게 늘어날 만큼 큰 호응을 얻고 있는 상품',
    subtit2: '유병자보험의 종류',
    cont2_list2: '1. 간편심사보험',
    cont2_list3: '2. 고혈압·당뇨병 특화보험',
    cont2_list4: '3. 무심사보험',
    cont2_list5: '4. 초간편보험 (HOT)',
    cont2_1: '고지의무 항목의 개수나 면제여부에 따라',
    cont2_2: '고지의무를 18개 항목에서 6개 항목으로 축소하고 입원·수술의 고지 기간을 5년 이내에서 2년 이내로 단축한 상품',
    cont2_3: '고혈압과 당뇨병에 대한 고지의무를 면제한 상품',
    cont2_4: '모든 질병 및 치료내역에 대한 고지의무와 건강검진 절차를 생략한 상품',
    cont2_5: '▲3개월 내 입원·수술·추가검사 ▲2년 이내 질병·사고로 입원·수술 ▲5년 이내 암 등의 진단·입원, 수술 기록 등 3가지 심사항목 중 하나만 충족하면 가입이 가능한 상품',
    subtit3: '유병자보험 가입요령 체크하기',
    cont3_1: '유병자보험은 병력이 있는 소비자도 보험가입을 할 수 있다는 선택의 폭이 넓어진 것은 사실이지만, 그렇다고 무턱대고 가입하는 것은 옳지 않습니다.\n가입시 꼭 알아 두셔야 할 내용은 다음과 같습니다',
    cont3_2: '1. 만성질환자라고 해서 무조건 유병자보험이 유리한 것은 아닙니다. 유병자보험은 일반 보험에 비해 보험료가 높고 보장도 제한되어 있기 때문에 일반 보험과 함께 알아보고 유리한 조건의 상품을 선택해야 합니다.',
    cont3_3: '2. 유병자보험은 갱신형으로 판매되는 경우가 많은데 향후 연령 증가에 따라 갱신 시 보험료가 인상될 수 있기 때문에 갱신주기가 적은 상품 혹은 비갱신형 함께 고려를 해야 합니다.',
    cont3_4: '3. 유병자보험은 기본적으로 보험료 수준이 높기 때문에 납입능력, 계약유지가능성을 반드시 고려해야 합니다.',
    cont3_5: '4. 고혈합·당뇨병 유병자만 가입할 수 있는 상품은 계약변경제도 중 보험가입 후에 더 이상 고혈압 또는 당뇨병 유병자가 아님을 증명하면 보험료가 저렴한 일반 상품으로 변경 가능합니다.',
    cont3_6: '5. 유병자보험의 고지의무는 일반 보험에 비해 완화된 수준일 뿐이지 제대로 고지를 하지 않거나 거짓 고지를 하는 경우 일반 보험과 마찬가지로 보장이 제한되거나 해지될 수 있음을 유의하여야 합니다.',
    cont3_7: '6. 유병자보험은 상품별/회사별 가입조건이 천차만별이기 때문에 반드시 비교가입 하시기를 권해드립니다.'
  },
  {
    id: '6',
    idx: '6',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: 'bold',
    list_style1: '',
    list_style2: '',
    next: '왜 필요할까? series 치아보험',
    prev: '왜 필요할까? series 유병자보험',
    class: '',
    class2: '',
    title: '왜 필요할까? series 종신보험',
    subtit1: '종신보험! 왜 필요할까?',
    cont1_list2: '첫번째 이유',
    cont1_list3: '두번째 이유',
    cont1_1: '피보험자가 사망할 때 까지를 보장기간으로 하여 피보험자가 사망한 후 유족들에게 보험금이 지급되는 보험을 말합니다. \n즉, 평생을 보장하는 상품으로 사망원인과 관계없이 보험금이 지급되기 때문에 꼭 한 번은 보험금을 받게 됩니다.',
    cont1_2: '가정경제에서 소득을 담당하는 가장이 갑작스럽게 사망하게 되면, 유가족의 생계는 위험에 빠지게 됩니다. \n그래서 가장의 조기사망에 대비하기 위한 목적으로 종신보험에 가입을 하게 됩니다.',
    cont1_3: '소득과는 무관하다고 생각할 수 있는 주부지만 사망시 가계경제는 위험에 빠지게 되며, 자녀양육과 살림에 대한 \n경제적인 가치를 환산하여 종신보험에 가입하는 경우도 있습니다. \n다만 부부가 동시에 종신보험에 가입하게 되면 보험료 부담이 상당할 것이기 때문에 주부의 경우자녀가 독립하는 시기까지만 사망을 보장하는 \n정기보험을 가입하는 것이 유리할 수 있습니다.',
    subtit2: '종신보험의 보장내용',
    cont2_list1: '1. 사망보험금 : ',
    cont2_list2: '2. 기타 : ',
    cont2_1: '사망원인과 시기에 관계없이 보험금 지급',
    cont2_2: '특약 선택으로 중대질병 진단금, 수술비, 입원비 등을 지급',
    subtit3: '종신보험 활용 Tip',
    cont3_list1: '1. 중도인출로 목돈 마련',
    cont3_list2: '2. 연금전환으로 노후대비 가능',
    cont3_1: '종신보험은 대출이나 중도인출이 가능한 상품으로 갑자기 목돈이 필요할 때는 종신보험을 해지하지 말고 이미 납입한 보험료의 일정 범위내에서 대출 또는 인출하여 사용할 수 있습니다. 또한 종신보험은 복리상품으로 원금(납입한 보험료)+이자에 이자가 적용되기 이율 면에서도 유리한 상품입니다.',
    cont3_2: '종신보험 가입시 연금전환 특약을 선택하면 자녀들이 성장하여 독립한 이후에는 연금으로 전환이 가능하여 본인의 노후를 위해 사용할 수 있습니다.',
    subtit4: '종신보험 가입시 체크해야 할 사항',
    cont4_list1: '1. 보장의 중복을 피해서 가입',
    cont4_list2: '2. 건강할 때 가입',
    cont4_list3: '3. 보험료 부담을 고려해서 가입',
    cont4_1: '이미 중대 질병을 보장하는 보험에 가입이 되어 있다면 보장이 중복되지 않도록 가입을 하셔야 보험료 부담을 줄일 수 있습니다. 즉, 암보험을 가지고 계시다면 종신보험에서 암 특약을 가입하지 않습니다.',
    cont4_2: '보험가입시 건강체로 가입을 하게 되면 보험료 할인을 받을 수 있습니다. 참고로 사망을 보장하는 보험이기 때문에 나이가 어릴수록 보험료 부담도 줄어듭니다.',
    cont4_3: '종신보험은 사망보험금이 클수록 보험료 부담도 큽니다. 따라서 주계약을 너무 높게 설정하지 말고 정기특약 등을 활용하게 되면 보험료 부담을 줄일 수 있습니다.',
  },
  {
    id: '7',
    idx: '7',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: '',
    list_style1: 'list_style1',
    list_style2: '',
    next: '왜 필요할까? series 화재보험',
    prev: '왜 필요할까? series 종신보험',
    class: '',
    class2: '',
    title: '왜 필요할까? series 치아보험',
    subtit1: '치아보험! 왜 필요할까?',
    cont1_1: '치과치료 중에 발생하는 비용을 보장받을 수 있는 보험으로\n임플란트, 브릿지, 틀니는 물론 크라운치료, 아말감, 레진 등이 보장범위에 포함됩니다.',
    cont1_3: '1. 치료비 대부분이 비급여 항목으로 되어 있기 때문에 환자 개인 부담금 큼 (치료비의 약 70%)',
    cont1_4: '2. 고액의 치료비용이 발생하는 치료항목인 임플란트 치료에 대한 대비가 필요',
    cont1_5: '3. 상해로 인한 치아보장이 반드시 필요',
    subtit2: '치아보험의 보장내용',
    cont2_1: '1. 보존치료 : 치료빈도가 많은 인레이, 레진, 아말감, 크라운 치료 등에 소요되는 비용',
    cont2_2: '2. 보철치료 : 목돈이 들어가는 임플란트, 틀니, 브릿지 등에 소요되는 비용',
    cont2_3: '3. 기타 : 특약 가입시 스케일링 비용, 영구발거비용까지 보장가능',
    subtit3: '치아보험 가입시 체크해야 할 사항',
    cont3_1: '1. 무진단형(일정기간이 지나야 보장가능)과 진단형(즉시 보장가능)에 중에 선택해서 가입할 수 있습니다.',
    cont3_2: '2. 무진단형은 면책기간(최장 1년)과 감액기간(면책기간 후 최장 2년)에 반드시 유의해야 합니다.',
    cont3_3: '3. 가입조건과 보장내용, 가입연령, 보험료, 특약 등을 비교해보고 유리한 상품을 선택해야 합니다.',
    cont3_4: '4. 상해로 인한 치아보장을 해주는 상품으로 가입하는 것이 유리합니다.',
    cont3_5: '5. 자녀를 위한 치과보험을 선택하실 경우 가입시기에 주의하셔야 합니다.',
  },
  {
    id: '8',
    idx: '8',
    type: '재테크',
    writer: '강세훈',
    date: '2022. 05. 03',
    bold: '',
    list_style1: 'list_style1',
    list_style2: '',
    next: '',
    prev: '왜 필요할까? series 치아보험',
    class: 'none',
    class2: '',
    title: '왜 필요할까? series 화재보험',
    subtit1: '화재보험! 왜 필요할까?',
    cont1_1: '화재로 인해 건물, 인테리어, 집기비품 등에 발생한 손해를 보상하는 보험으로, 화재로 인한 직접손해 뿐만 아니라, 화재에 따른 소방손해, 피난손해 등을 보상하는 보험입니다.\n보장하는 기간에 따라 장기화재보험과 일반화재보험으로 나뉩니다.',
    cont1_3: '1. 비교적 적은 비용으로 화재로 손해를 입었을 때 원상복구에 필요한 보장을 받을 수 있습니다.',
    cont1_4: '2. 재산상의 피해 이외에 배상책임 및 벌금 등도 보장도 가능합니다.',
    cont1_5: '3. 최근에는 화재 뿐만 아니라 배상책임 법률비용, 상해담보까지 보장이 가능하고 급배수시설, 12대 가전제품 수리비도 보상되기 때문에 보상 목록을 자세히 확인하고 가입하는 것이 중요합니다.',
    subtit2: '화재보험의 가입대상',
    cont2_1: '주택(아파트, 연립, 단독주택 등) 상가, 공장 등',
    subtit3: '화재보험의 보장내용',
    cont3_list1: '1. 화재손해',
    cont3_list2: '2. 발생비용',
    cont3_list3: '3. 선택가입시',
    cont3_1: '건물의 부속물, 건물의 부착물, 건물의 부속설비 등의 직접손해, 소방손해, 피난손해단, 통화, 유가증권, 인지, 우표나 자동차 등은 제외',
    cont3_2: '잔존물제거비용, 손해방지비용, 대위권보전비용, 잔존물보존비용 등',
    cont3_3: '풍수재손해, 점포휴업손해, 도난손해, 전기위험, 구내폭발파열, 붕괴/침강 및 사태로 인한 손해, 시설수리비용, 야외간판풍수재손해, 임시거주비, 임대인의 임대료손실 등',
    subtit4: '화재보험 가입시 체크해야 할 사항',
    cont4_list1: '1. 장기보험과 일반보험 중 유리한 상품 선택',
    cont4_list2: '2. 보험가입금액 설정에 주의',
    cont4_list3: '3. 보험가입시 용어의 의미를 충분히 이해',
    cont4_1: '보장기간에 따라 1년 단위로 가입하는 일반보험과 3년이상 가입하는 장기보험으로 구분되며 일반보험은 보험료를 1회 납입하고 1년간 보장받으며 만기환급금 없이 보장보험료로만 구성되며 장기보험은 매월 보험료를 납입하고 만기시 만기환급금이 발생합니다.',
    cont4_2: '화재시 원상복구비용을 보상하기 때문에 너무 높은 금액을 설정하게 되면 보험료만 많이 부담하게 됩니다. 건물가액을 설정할 때 평당 약 100만원 정도가 적합하고 인테리어나 집기비품을 추가하면 됩니다.',
    cont4_3: '화재보험에서 보장하는 내용이 생소하기 때문에 가입시 충분히 이해하고 가입을 해야하며 보험 전문가와 반드시 상의를 하고 가입을 해야 합니다.',
  },
]
export const getPostByNo = id => {
  const array = Data.filter(x => x.id === id);
  if (array.length === 1) {
    return array[0];
  }
  return null;
}