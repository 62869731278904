import sucCaseIcon1 from '../img/sub/sucCaseIcon1.png';
import sucCaseIcon2 from '../img/sub/sucCaseIcon2.png';


export const Data = [
  {
    id: '1',
    icon: sucCaseIcon1,
    lounge: 'DB',
    award: '영업에 성공을 담다_세연본부',
    content1: '1. 간단하게 본인 소개 부탁드리겠습니다.',
    content1_2: '안녕하세요 세연본부 직할 사업단 정지만FP입니다. * *금융서비스에서 근무를 하다 지금은 세연본부 직할 사업단에서 활동하고 있습니다.',
    content2: '2. 어떤 계기로 아이티엑스마케팅이라는 회사에서 일 하시게 되었나요?',
    content2_2: '직전 직장이었던 **금융서비스에서 지인영업을 하던 것에 회의감을 느끼고 있었을 때 영업을 하는데 좀 더 나은 방향성에 대해서 찾다 보니 아이티엑스마케팅이라는 회사의 방침인 1인 1시장을 제공한다는 내용이 제가 생각했던 것과 일치하여 아이티엑스마케팅으로 입사하게 되었습니다.',
    content3: '3.	영업적인 환경에서 아이티엑스마케팅 이전에 있던 회사와 가장 큰 차이점은 무엇인가요?',
    content3_2: '이전에 있던 **금융서비스라는 회사는 영업채널이 소규모 밖에 없었습니다. 지인시장 또는 개척 그래서 여러 가지 고민을 하였고 다양한 영업채널을 만들어 보고자 중소기업 개척 DB등 회사에 어필을 하였으나 이루어지는 부분이 없었습니다. 하지만 아이티엑스마케팅이란 회사에서는 이름에 맞게 여러 가지 영업채널을 보유하고 있으며 자신의 성향과 제일 잘 맞는 영업채널을 이용하여 성공할 수 있는 방향을 잡아준다는 것이 가장 큰 차이점이라고 생각합니다.',
    content4: '4.	아이티엑스마케팅에서 영업활동 하시면서 가장 큰 성과 또는 보람은 무엇인가요?',
    content4_2: '가장 큰 성과는 지금의 팀원들과 함께 영업에 대한 어려움 및 해결방안을 함께 고민하고 함께 성장하는 것입니다. 다양한 전문가들과 지식을 공유할 수 있다는 것이 큰 힘이 됩니다. DB영업의 특징은 지인과 달리 고객을 파악하는 것이 가장 중요한데 이런 점에서 다양한 경험이 있는 팀원들과의 지식공유 또 서로의 경험을 바탕으로 고객님께 맞는 최적의 보장분석을 제공하고 고객님께서 만족해 하실 때 큰 보람을 느낍니다.',
    content5: '5. 보험설계사가 성공하기 위해 가장 중요한 것은 무엇이라고 생각하시나요?',
    content5_2: '보험영업을 성공하기 위해 가장 중요한 것은 보험에 대한 지식과 마인드가 가장 중요하다고 생각합니다. 보험에 대한 지식은 누가 들어도 당연한 이야기지만 보험에 대한 지식을 끊임없이 공부하지 않으면 나중에 사상누각이 될 수 있기 때문입니다. 마인드가 중요한 이유는 영업을 하면서 다양한 돌발상황에 마주하게 되는데 예를 들면 만나 뵙기로 한 고객님의 변심으로 방문이 무산되거나 아니면 고객님이 화를 내는 등의 돌발상황이 발생하게 되면 그것 때문에 좌절하거나 의욕이 저하되는 등의 문제점이 생길 수 있기 때문입니다. 문제점과 해결책을 찾는 것에 가장 큰 도움을 주는 것이 바로 본인의 마인드 라고 생각합니다.',
    content6: '6. 영업적인 부문에서 회사와 지원이 필요하다면 어떤 것이 있을까요?',
    content6_2: 'DB채널의 다각화가 필요하다고 생각합니다. 한가지 DB만 믿고 그 채널만으로 영업을 한다면 해당 DB가 없어지거나 회사의 사정으로 공급이 원활하지 않으면 아무 것도 할 수 없는 상태가 됩니다. 최소 3~4채널 혹은 그 이상의 DB채널을 만들어 주시면 더욱 더 큰 방향으로 발전할 수 있다고 생각합니다.',
    linkBtn: '+ more',
    next: '영업에 성공을 담다_보험플러스',
    prev: '',
    class: '',
    class2: 'none'
  },
  {
    id: '2',
    icon: sucCaseIcon2,
    lounge: '병원 Lounge',
    award: '영업에 성공을 담다_보험플러스',
    content1: '1. 간단하게 본인 소개 부탁드리겠습니다.',
    content1_2: '저는 아이티엑스마케팅 서울본부 신사사업단장 김좌용입니다.',
    content2: '2. 어떤 계기로 아이티엑스마케팅이라는 회사에서 일 하시게 되었나요?',
    content2_2: '現서울본부의 수장인 남효석 본부장님과는 전 보험회사에서 지점장님과 FC의 관계로 있었습니다. 원수사에서 항상 대리점으로 옮기고 싶다고 생각할 때 쯤 당시 지점장님이셨던 現본부장님이 아이티엑스마케팅이라는 회사에서 함께 하자는 권유를 받았고, 본부장님과 함께라면 새로운 도전도 할 수 있겠다는 생각이 들어 본부장님을 따라 아이티엑스마케팅에 정착하게 되었습니다.',
    content3: '3.	영업적인 환경에서 아이티엑스마케팅 이전에 있던 회사와 가장 큰 차이점은 무엇인가요?',
    content3_2: '이전에 있던 보험회사는 원수사였기 때문에 아이티엑스마케팅은 여러 보험회사를 함께 취급할 수 있다는 점입니다. 고객의 needs에 맞춰 설계가 가능하고 고객이 원하는 보험사 및 상품을 권유할 수 있다는것이 큰 차이점이라고 생각합니다. 또한 원수사에서는 현재 아이티엑스마케팅과 같은 DB를 얻을 수 있는 방법이 기존 회사의 담당자가 없어진 계약자를 받는 방법 뿐이였습니다. 하지만 아이티엑스마케팅에 오면서 퍼미선DB, 소상공인DB, 병원라운지, 보험플러스 등 다방면으로 고객을 만날 수 있고 또 영업할 수 있는 시장이 제공된다는 점이 가장 큰 차이점이라고 생각합니다.',
    content4: '4.	아이티엑스마케팅에서 영업활동 하시면서 가장 큰 성과 또는 보람은 무엇인가요?',
    content4_2: '가장 큰 성과는 당연히 병원라운지 영업이라고 생각합니다. 그 이유는 병원라운지에서 얻은 성과는 아이티엑스마케팅이 아니라면 얻지 못했을 성과이기 때문입니다. 저의 영업방법은 항상 고객에게 도움이 되고자 찾아다녀야 했던 영업이였다면, 병원라운지는 저의 도움이 필요한 사람들이 직접 찾아오는 영업이라고 생각을 했습니다. 아무것도 아니지만 보험금청구를 하고 사후 관리를 통해 고객들이 감사하다고 할 때, 또한 고객과의 상담을 통해서 고객이 생각하지 못했던 보험금을 받게 해주었을때 큰 보람을 느꼈던 것 같습니다. 그러다 보니 자연스럽게 고객들은 저를 신뢰하게 되고, 본인의 보험을 상담받게 되면서 본인이 부족한 보장을 채워야 겠다는 생각을 하게 되는 선순환으로 영업할 수 있는 곳이 병원라운지라고 생각합니다.',
    content5: '5. 보험설계사가 성공하기 위해 가장 중요한 것은 무엇이라고 생각하시나요?',
    content5_2: '성공하기 위해서는 많은 것들이 중요하지만 그중 하나를 뽑자면 ‘고객을 발굴하기 위한 꾸준함’이 아닐까 생각합니다. 내가 아무리 보험에 대해서 지식이 많고, 상담의 스킬이 뛰어난다고 해도 내 앞에 앉혀놓고 상담할 고객이 없으면 다 무용지물이라고 생각하기 때문입니다.',
    content6: '6. 영업적인 부문에서 회사와 지원이 필요하다면 어떤 것이 있을까요?',
    content6_2: '아이티엑스마케팅 영업의 1선에서 일하고 있는 모든 FP분들이 본인의 역량을 발휘하여 양질의 다양한 고객들을 발굴 수 있도록 그 통로 및 시장을 많이 만들어주셨으면 좋겠습니다. 또한 그 시장을 발굴하기 전에는 FP들의 의견이 반영할 수 있는 창구가 있었으면 좋겠습니다. 아이티엑스마케팅에는 각자의 방식으로 영업을 잘 하시는 분들이 많을 텐데 그 분들의 의견이 반영이 된다면 현재 영업의 환경을 이해하고 영업의 시장이 만들어진다면 더 좋은 성과가 이루어 지지 않을까 생각합니다.',
    linkBtn: '+ more',
    next: '',
    prev: '영업에 성공을 담다_세연본부',
    class: 'none',
    class2: ''
  },
];

export const getPostByNo = id => {
  const array = Data.filter(x => x.id === id);
  if (array.length === 1) {
    return array[0];
  }
  return null;
}